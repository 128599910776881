//
// Buttons Base
//

// Button
.btn {
	--#{$prefix}btn-color: var(--kt-body-color);
	--#{$prefix}btn-bg: transparent;
	--#{$prefix}btn-border-color: transparent;

	// Reset outline
	outline: none !important;

	// Reset focus shadow
	&:not(.btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg):not(.shadow-xs) {
		box-shadow: none !important;
	}

	// Remove border
	&:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
		border: 0;
		padding: calc(#{$btn-padding-y} + #{$btn-border-width}) calc(#{$btn-padding-x} + #{$btn-border-width});

		&.btn-lg {
			padding: calc(#{$btn-padding-y-lg} + #{$btn-border-width}) calc(#{$btn-padding-x-lg} + #{$btn-border-width});
		}

		&.btn-sm {
			padding: calc(#{$btn-padding-y-sm} + #{$btn-border-width}) calc(#{$btn-padding-x-sm} + #{$btn-border-width});
		}
	}

	// Link
	&.btn-link {
		border: 0;
		border-radius: 0;
		padding-left: 0 !important;
		padding-right: 0 !important;
		text-decoration: none;
		font-weight: $btn-font-weight;
	}

	// Outline
	&.btn-outline:not(.btn-outline-dashed) {
		border: 1px solid var(--kt-input-border-color);
	}

	// Outline dashed
	&.btn-outline-dashed {
		border: 1px dashed var(--kt-input-border-color);
	}

	// Flush
	&.btn-flush {
		@include button-reset();
	}

	// Flex
	&.btn-flex {
		display: inline-flex;
		align-items: center;
	}

	// Align start
	&.btn-trim-start {
		justify-content: flex-start !important;
		padding-left: 0 !important;
	}

	// Align start
	&.btn-trim-end {
		justify-content: flex-end !important;
		padding-right: 0 !important;
	}
}

// Icons
.btn {

	// Font icon
	i {
		display: inline-flex;
		font-size: $font-size-base;
		padding-right: 0.35rem;
		vertical-align: middle;
		line-height: 0;
	}

	// Svg icon
	.svg-icon {
		flex-shrink: 0;
		line-height: 0;
		margin-right: 0.5rem;
	}

	// Icon only button
	&.btn-icon {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 0;
		height: $input-height;
		width: $input-height;

		// Remove border
		&:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush) {
			border: 0;
		}

		// Sizes
		&.btn-sm {
			height: $input-height-sm;
			width: $input-height-sm;

		}

		&.btn-lg {
			height: $input-height-lg;
			width: $input-height-lg;
		}

		&.btn-circle {
			border-radius: 50%;
		}

		i,
		.svg-icon {
			padding: 0;
			margin: 0;
			line-height: 1;
		}
	}
}

.onoffswitch {
	position: relative;
	width: 155px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}

.onoffswitch-checkbox {
	display: none;
}

.onoffswitch-label {
	display: block;
	overflow: hidden;
	cursor: pointer;
	border: 2px solid #999999;
	border-radius: 20px;
	width: 85%;
	height: 25px
}

.onoffswitch-inner {
	display: block;
	width: 200%;
	margin-left: -100%;
	transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
	display: block;
	float: left;
	width: 50%;
	height: 30px;
	padding: 0;
	line-height: 30px;
	font-size: 14px;
	color: white;
	font-family: Trebuchet, Arial, sans-serif;
	font-weight: bold;
	box-sizing: border-box;
}

.onoffswitch-inner:before {
	content: "Included!";
	padding-left: 10px;
	background-color: green;
	color: #FFFFFF;
}

.onoffswitch-inner:after {
	content: "Excluded";
	padding-right: 10px;
	background-color: red;
	color: #FFFFFF;
	text-align: right;
	max-width: 85%;
	height: 25px
}

.onoffswitch-switch {
	display: block;
	width: 46px;
	margin: 2px;
	background: #FFFFFF;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 105px;
	border: 2px solid #999999;
	border-radius: 20px;
	transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
	margin-left: 0;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
	right: 23px;
}




//   .button-cover {
// 	height: 100px;
// 	margin: 20px;
// 	background-color: #fff;
// 	box-shadow: 0 10px 20px -8px #c5d6d6;
// 	border-radius: 4px;
//   }

//   .button-cover:before {
// 	counter-increment: button-counter;
// 	content: counter(button-counter);
// 	position: absolute;
// 	right: 0;
// 	bottom: 0;
// 	color: #d7e3e3;
// 	font-size: 12px;
// 	line-height: 1;
// 	padding: 5px;
//   }


.knobs,
.layer {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.button {
	position: relative;
	top: 50%;
	width: 120px;
	height: 36px;
	overflow: hidden;
	font-family:null ;
}

.button.r,
.button.r .layer {
	border-radius: 100px;
}

.button.b2 {
	border-radius: 2px;
}


.checkbox {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	opacity: 0;
	cursor: pointer;
	z-index: 3;
}

.knobs {
	z-index: 2;
}

.layer {
	width: 100%;
	background-color: #fcebeb;
	transition: 0.3s ease all;
	z-index: 1;
}


/* Button 16 */
#button-16 .knobs:before {
	content: "Blacklist";
	position: absolute;
	top: 2px;
	left: 4px;
	width: 72px;
	height: 32px;
	color: #fff;
	text-align: center;
	line-height: 1;
	padding: 11px 4px;
	background-color: #f44336;
	border-radius: 2px;
	transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
}

#button-16 .checkbox:active+.knobs:before {
	width: 46px;
}

#button-16 .checkbox:checked:active+.knobs:before {
	margin-left: -26px;
}

#button-16 .checkbox:checked+.knobs:before {
	content: "Whitelist";
	left: 42px;
	background-color: #50CC89;
}

#button-16 .checkbox:checked~.layer {
	background-color: #ebfcf5;
}
.react-date-picker__wrapper{
	border: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// .MuiTableHead-root .MuiTableRow-root .MuiTableCell-head {
//     font-weight: bold;
//     resize: horizontal;
//     overflow-x: overlay;
//     overflow-y: hidden;
//     border-right: 1px solid;
// }