//
// Theme style
//

// Initialize
@import "init";

// Components
@import "./core/components/components";
@import "components/components";

// Layout
@import "./core/layout/base/layout";
@import "layout/layout";
// #kt_app_content {
//     background-color: #fff  !important;
// }
  .rdt_Table {
    border-radius: 3px  ;
    border:1px solid #F2F5F5 !important;
    
  }
  .rdt_Table .rdt_TableHead .rdt_TableHeadRow {
    background-color:#F8FBFC;
    min-height: 0;
    height: 50px;
   
  }
  .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
    padding: 0.72rem 1.5rem;
  }
  .rdt_TableRow {
    border-bottom: 1px solid #F2F5F5 !important;
  }

  .rdt_Table
    .rdt_TableHead
    .rdt_TableHeadRow
    .rdt_TableCol
    .rdt_TableCol_Sortable {
    color: #5C6068;
    font-weight: bold;
    font-style: 0.857rem;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
  .rdt_Table
    .rdt_TableHead
    .rdt_TableHeadRow
    .rdt_TableCol
    .rdt_TableCol_Sortable:hover {
    color: #6e6b7b;
  }
  .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell {
    padding: 0px 1.5rem;
  }
  .rdt_Table
    .rdt_TableBody
    .rdt_TableRow
    .rdt_TableCell
    .column-action
    svg {
    stroke: #6e6b7b;
  }
  .rdt_Table
    .rdt_TableBody
    .rdt_TableRow
    .rdt_TableCell
    .column-action
    .dropdown-item:hover
    svg {
    stroke: #5ccdde;
  }
  .rdt_Table
    .rdt_TableBody
    .rdt_TableRow
    .rdt_TableCell
    .column-action
    .dropdown-item:active
    svg {
    stroke: #fff;
  }
  .rdt_Table
    .react-paginate.separated-pagination
    .page-item:first-child
    .page-link,
  .rdt_Table
    .react-paginate.separated-pagination
    .page-item:last-child
    .page-link {
    border-radius: 50%;
  }
  .rdt_Pagination select {
    min-width: 30px;
  }
  .rdt_Pagination select:focus,
  .rdt_Pagination select:active {
    outline: 0;
  }
  .dataTable-filter {
    max-width: 220px;
  }
  .dataTable-select {
    width: 5rem;
  }
  .dataTable-select:not([multiple="multiple"]) {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    background-position: calc(100% - 12px) 10px, calc(100% - 20px) 12px, 100% 0;
    background-size: 10px 10px, 10px 10px;
  }
  .rdt_TableBody {
    padding:0 !important;
  }
//   #kt_app_content {
//     background-color: #fff;
//   }
//   .rdt_TableRow {
//     margin-top:2px;
//     margin-bottom: 2px;
//     border-radius: 2px;
//   }

  .linear-load{
    position:fixed;
    width:100%;
    left:0;right:0;top:0;bottom:0;
    background-color: rgba(255,255,255,0.7);
    z-index:9999;
    display:none;
}

@-webkit-keyframes spin {
	from {-webkit-transform:rotate(0deg);}
	to {-webkit-transform:rotate(360deg);}
}

@keyframes spin {
	from {transform:rotate(0deg);}
	to {transform:rotate(360deg);}
}

.linear-load:after {
    content:'';
    display:block;
    position:absolute;
    left:48%;top:40%;
    width:40px;height:40px;
    border-style:solid;
    border-color:black;
    border-top-color:transparent;
    border-width: 4px;
    border-radius:50%;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
}

.loading-overlay {
    display: flex;
    background: rgba(255, 255, 255, 0.7);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9998;
    align-items: center;
    justify-content: center;
  }
  .br-none {
    border-radius: 0px !important;
  }
  .filter-backdrop {
        position: fixed;
        z-index: 140;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: hsla(0,0%,100%,.9);
        opacity: .5;
        display: none;
  }

  .filter-backdrop.show {
    display: block;
}

.pl-0{
    padding-left:0px !important;
}
.pr-0 {
    padding-right:0px !important;;
}
.ml-0 {
    margin-left:0px !important;;
}
.mr-0{
    margin-right:0px !important;;
}
.rdt_TableCell {
  word-break:normal !important;
}
.rdt_TableCell div,
.rdt_TableCol div {
    white-space: unset !important;
    overflow: unset !important;
    text-overflow: unset !important;
    
}
.rdt_Pagination {
    border:0px !important;
}
.not-allowed {
  cursor: not-allowed;
}
.select__multi-value {
  background-color: var(--bs-primary) !important;
  color:var(--bs-white) !important;
}
.select__multi-value__label {
  color:var(--bs-white) !important;
}

.switch-custom {
  position: relative;
    width: 180px;
    border-radius: 4px;
    height: 39px;
    flex: 0 0 134px;
    border-radius: 4px;
    background-color: var(--kt-white);
    border: 1px solid var(--kt-gray-300) !important;
    margin:0px !important;
}

.switch-label {
  position: relative;
  z-index: 2;
  float: left;
  text-transform: Uppercase;
  color: rgb(255, 255, 255);
  text-align: center;
  cursor: pointer;
  color:  var(--kt-gray-700);
  line-height: 36px;
  width: 88px;
  padding-left: 5px;
  font-size: 10.5px;
}


.switch-label-off {
  padding-left: 2px;
}

.switch-label-on {
  padding-right: 2px;
}

.switch-input {
  display: none;
}

.switch-input:checked+.switch-label {
  color: var(--bs-white);
  text-shadow: 0 1px rgba(255, 255, 255, 0.25);
  -webkit-transition: 0.15s ease-out;
  -moz-transition: 0.15s ease-out;
  -ms-transition: 0.15s ease-out;
  -o-transition: 0.15s ease-out;
  transition: 0.15s ease-out;
  -webkit-transition-property: color;
  -moz-transition-property: color;
  -ms-transition-property: color;
  -o-transition-property: color;
  transition-property: color;
  font-weight: 600;
}

.switch-input:checked+.switch-label-on~.switch-selection {
  left: 50%;
  /* Note: left: 50%; doesn't transition in WebKit */
}

.switch-selection {
  position: absolute;
  z-index: 1;
  top: 2px;
  left: 2px;
  display: block;
  width: 88px;
  height: 32px;
  border-radius: 3px;
  background-color: var(--bs-primary);
  color:var(--bs-white) !important;
  -webkit-transition: left 0.15s ease-out;
  -moz-transition: left 0.15s ease-out;
  -ms-transition: left 0.15s ease-out;
  -o-transition: left 0.15s ease-out;
  transition: left 0.15s ease-out;
}
.tab-wizard li span.nav-link{
  font-size: 1rem !important;
  font-weight: 600;
  cursor: pointer;
  border-radius: 2px;
}
.tab-wizard li span.nav-link.active {
  font-weight: bold;
}
.select__placeholder {
  color:var(--kt-input-color) !important;
  font-family: var(--bs-body-font-family) !important;
  font-size: 1.1rem !important;
}
.select__control {
  min-height: 44px !important;
  border:1px solid var(--kt-input-border-color) !important;
  border-radius: 0.475rem !important;
}
.creative {
  // height: 50px;
  border:1px solid var(--kt-gray-300);
  display: flex;
  align-items:center;
  justify-content: center;
  position: relative;
  
}
.preview-icon img{
  width: 40px;
  height: 40px;
}
.preview-icon {
  padding:5px 5px;
}
// .creative-description {
//   flex: 0 1 auto;
//   width: 150px;
//   // height: 50px;
//   position: absolute;
//   left: 40%;
//   transform: translateX(-50%);
//   top: 5px;
//   font-weight: bold;
// }
.creative-action{
  flex: 0 1;
  width: 100px;
  // height: 50px;
  margin-left: auto;
  
}
.creative-action button {
  border-radius: 0px;
  // margin-top:8px;
  margin-right: 5px;
}
.creative-description {
  flex: 0 1 auto;
  /* width: 150px; */
  /* height: 50px; */
  /* position: absolute; */
  left: 40%;
  /* transform: translateX(-50%); */
  top: 5px;
  margin-left:5px;
  font-weight: bold;
}