//
// Buttons Theme
//

// Outline dashed basic style
.btn.btn-outline.btn-outline-dashed {
	border-width: 1px;
	border-style: dashed;	
	
	$color: null;
	$icon-color: null;
	$border-color: null;
	$bg-color: null;;

	$color-active: null;
	$icon-color-active: null;
	$border-color-active: var(--kt-primary);
	$bg-color-active: null;

	@include button-custom-variant($color, $icon-color, $border-color, $bg-color, $color-active, $icon-color-active, $border-color-active, $bg-color-active);
}

// Theme colors
@each $name, $value in $theme-colors {
	// Base
	.btn.btn-#{$name} {
		$color: var(--kt-#{$name}-inverse);
		$icon-color: var(--kt-#{$name}-inverse);
		$border-color: var(--kt-#{$name});
		$bg-color: var(--kt-#{$name});

		$color-active: var(--kt-#{$name}-inverse);
		$icon-color-active: var(--kt-#{$name}-inverse);
		$border-color-active: var(--kt-#{$name}-active);
		$bg-color-active: var(--kt-#{$name}-active);

		@include button-custom-variant($color, $icon-color, $border-color, $bg-color, $color-active, $icon-color-active, $border-color-active, $bg-color-active);
	}

	// Light
	@if ( theme-light-color($name) ) {
		.btn.btn-light-#{$name} {
			$color: var(--kt-#{$name});
			$icon-color: var(--kt-#{$name});
			$border-color: var(--kt-#{$name}-light);
			$bg-color: var(--kt-#{$name}-light);
	
			$color-active: var(--kt-#{$name}-inverse);
			$icon-color-active: var(--kt-#{$name}-inverse);
			$border-color-active: var(--kt-#{$name});
			$bg-color-active: var(--kt-#{$name});
	
			@include button-custom-variant($color, $icon-color, $border-color, $bg-color, $color-active, $icon-color-active, $border-color-active, $bg-color-active);
		}
	}	

	// Background
	.btn.btn-bg-#{$name} {
		$color: null;
		$icon-color: null;
		$border-color: var(--kt-#{$name});
		$bg-color: var(--kt-#{$name});		

		$color-active: null;
		$icon-color-active: null;
		$border-color-active: null;
		$bg-color-active: null;			

		@include button-custom-variant($color, $icon-color, $border-color, $bg-color, $color-active, $icon-color-active, $border-color-active, $bg-color-active);
	}
	
	// Active
	.btn.btn-active-#{$name} {
		$color: null;
		$icon-color: null;
		$border-color: null;
		$bg-color: null;

		$color-active: var(--kt-#{$name}-inverse);
		$icon-color-active: var(--kt-#{$name}-inverse);
		$border-color-active: var(--kt-#{$name});
		$bg-color-active: var(--kt-#{$name});

		@include button-custom-variant($color, $icon-color, $border-color, $bg-color, $color-active, $icon-color-active, $border-color-active, $bg-color-active);
	}

	// Light
	@if ( theme-light-color($name) ) {
		.btn.btn-active-light-#{$name} {
			$color: null;
			$icon-color: null;
			$border-color: null;
			$bg-color: null;

			$color-active: var(--kt-#{$name});
			$icon-color-active: var(--kt-#{$name});
			$border-color-active: var(--kt-#{$name}-light);
			$bg-color-active: var(--kt-#{$name}-light);

			@include button-custom-variant($color, $icon-color, $border-color, $bg-color, $color-active, $icon-color-active, $border-color-active, $bg-color-active);
		}
	}

	// Outline
	.btn.btn-outline.btn-outline-#{$name} {
		$color: var(--kt-#{$name});
		$icon-color: var(--kt-#{$name});
		$border-color: var(--kt-#{$name});
		$bg-color: transparent;

		$color-active: var(--kt-#{$name}-active);
		$icon-color-active: var(--kt-#{$name}-active);
		$border-color-active: var(--kt-#{$name});
		$bg-color-active: var(--kt-#{$name}-light);

		@include button-custom-variant($color, $icon-color, $border-color, $bg-color, $color-active, $icon-color-active, $border-color-active, $bg-color-active);
	}
}

// Theme text colors
@each $name, $value in $theme-text-colors {
	// Text and icon colors
	.btn.btn-color-#{$name} {
		$color: var(--kt-text-#{$name});
		$icon-color: var(--kt-text-#{$name});
		$bg-color: null;
		$border-color: null;

		$color-active: null;
		$icon-color-active: null;
		$border-color-active: null;
		$bg-color-active: null;

		@include button-custom-variant($color, $icon-color, $border-color, $bg-color, $color-active, $icon-color-active, $border-color-active, $bg-color-active);
	}

	// Text and icon colors active state
	.btn.btn-active-color-#{$name} {
		$color: null;
		$icon-color: null;
		$border-color: null;
		$bg-color: null;

		$color-active: var(--kt-text-#{$name});
		$icon-color-active: var(--kt-text-#{$name});
		$border-color-active: null;
		$bg-color-active: null;

		@include button-custom-variant($color, $icon-color, $border-color, $bg-color, $color-active, $icon-color-active, $border-color-active, $bg-color-active);
	}

	// Icon colors 
	.btn.btn-icon-#{$name} {
		$color: null;
		$icon-color: var(--kt-text-#{$name});
		$bg-color: null;
		$border-color: null;

		$color-active: null;
		$icon-color-active: null;
		$border-color-active: null;
		$bg-color-active: null;

		@include button-custom-variant($color, $icon-color, $border-color, $bg-color, $color-active, $icon-color-active, $border-color-active, $bg-color-active);
	}

	// Icon colors active state
	.btn.btn-active-icon-#{$name} {
		$color: null;
		$icon-color: null;
		$bg-color: null;
		$border-color: null;

		$color-active: null;
		$icon-color-active: var(--kt-text-#{$name});
		$border-color-active: null;
		$bg-color-active: null;

		@include button-custom-variant($color, $icon-color, $border-color, $bg-color, $color-active, $icon-color-active, $border-color-active, $bg-color-active);
	}

	// Text colors
	.btn.btn-text-#{$name} {
		$color: var(--kt-text-#{$name});
		$icon-color: null;
		$bg-color: null;
		$border-color: null;

		$color-active: null;
		$icon-color-active: null;
		$border-color-active: null;
		$bg-color-active: null;

		@include button-custom-variant($color, $icon-color, $border-color, $bg-color, $color-active, $icon-color-active, $border-color-active, $bg-color-active);
	}

	// Text colors active state
	.btn.btn-active-text-#{$name} {
		$color: null;
		$icon-color: null;
		$bg-color: null;
		$border-color: null;

		$color-active: var(--kt-text-#{$name});
		$icon-color-active: null;
		$border-color-active: null;
		$bg-color-active: null;

		@include button-custom-variant($color, $icon-color, $border-color, $bg-color, $color-active, $icon-color-active, $border-color-active, $bg-color-active);
	}	
}

// Social colors
@each $name, $value in $social-colors {
	// Base
	.btn.btn-#{$name} {
		$color: get($value, inverse);
		$icon-color: get($value, inverse);
		$border-color: get($value, base);
		$bg-color: get($value, base);

		$color-active: null;
		$icon-color-active: null;
		$border-color-active: get($value, active);
		$bg-color-active: get($value, active);

		@include button-custom-variant($color, $icon-color, $border-color, $bg-color, $color-active, $icon-color-active, $border-color-active, $bg-color-active);
	}

	// Light
	.btn.btn-light-#{$name} {
		$color: get($value, base);
		$icon-color: get($value, base);
		$bg-color: get($value, light);
		$border-color: get($value, light);

		$color-active: get($value, inverse);
		$icon-color-active: get($value, inverse);
		$bg-color-active: get($value, base);
		$border-color-active: get($value, base);

		@include button-custom-variant($color, $icon-color, $border-color, $bg-color, $color-active, $icon-color-active, $border-color-active, $bg-color-active);
	}


	  
}

.toggle {
	position: absolute;
	width: 50%;
	background-color: #fff;
	box-shadow: 0 2px 15px rgba(0,0,0,.15);
	transition: transform .3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  .names {
	font-size: 90%;
	font-weight: bolder;
	width: 65%;
	margin-left: 17.5%;
	margin-top: .5%;
	position: absolute;
	display: flex;
	justify-content: space-between;
	user-select: none;
  }

  .switch {
	width: 100%;
	background-color: rgba(0,0,0,.1);
	border-radius: 100px;
	position: relative;
	margin: 1.8rem 0 4rem 0; 
	cursor: pointer;
		.toggle {
			height: 2.8rem;
			border-radius: 100px;
		}
  }
 
  