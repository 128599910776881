//
// Floating label
//

.form-floating {
    .form-control.form-control-solid {
        &::placeholder {
            color: transparent;   
        }
    } 
}
.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
  opacity: .85;
  transform: scale(.85) translateY( -2.25rem) translateX(.15rem);
}
     